// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";


@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

// ck editor

.ck.ck-editor__editable_inline {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

// font sizes
.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-40 {
  font-size: 40px !important;
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

* {
  font-family: "Poppins", sans-serif;
}

// apex-tooltip absence in documentation.
.apexcharts-tooltip-marker {
  width: 8px !important;
  height: 8px !important;

}


// in some places  dblclick lead to selection. to disable this we need thi class.
// https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


//.css-11wu74z-MuiButtonBase-root-MuiTab-root {
//  min-width: 0 !important;
//}
//.css-19kzrtu{
//  padding: 10px 5px!important;
//}


// Form upload
.form-upload {
  width: 150px;
  height: 150px;
  overflow: hidden;
  &.loading {
    .ant-progress {
      z-index: 3;
    }
    .actions {
      opacity: 0;
    }
    &::after {
      z-index: 2;
      background-color: #ffffff8f;
      opacity: 1;
    }
  }
  &:hover {
    &::after {
      opacity: 1;
    }
    .actions button {
      opacity: 1;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #00000060;
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  img {
    object-fit: scale-down;
  }
  .actions {
    z-index: 2;
    left: 50%;
    bottom: 20%;
    transform: translateX(-50%);
    button {
      cursor: pointer;
      border: none;
      background: none;
      outline: none;
      font-size: 1.2rem;
      color: #fff;
      opacity: 0;
      transition: all 0.2s linear;
      &:hover {
        transform: scale(1.3);
      }
    }
  }
}

@media (max-width: 768px) {
  .form-upload {
    &::after {
      background-color: #00000030;
      opacity: 1;
    }
    .actions {
      button {
        opacity: 1;
      }
    }
  }
}



.expired_event {border-width: 1px 1px 1px 4px; border-color: transparent transparent transparent #09dc00 !important; padding-left: 5px}
.pending_event {border-width: 1px 1px 1px 4px; border-color: transparent transparent transparent #ffd672 !important; padding-left: 5px}
.notstarted_todo {border-width: 1px 1px 1px 4px; border-color: transparent transparent transparent #dc453b !important; padding-left: 5px}
.pending_todo {border-width: 1px 1px 1px 4px; border-color: transparent transparent transparent #ffd672 !important; padding-left: 5px}
.finished_event {border-width: 1px 1px 1px 4px; border-color: transparent transparent transparent #09dc00 !important; padding-left: 5px}

.ant-select-dropdown{ z-index: 9999}
.custom-map-control-button{
  background: #fff;
  border-color: transparent;
  margin-right: 5px;
  border-radius: 20px;
}

.fc-button {
  background-color: #f4f5f8!important;
  border: 0!important;
  text-shadow: none!important;
}
.fc-icon, .fc-button {
  color: #333!important;
}
.fc-col-header-cell-cushion, .fc-col-header-cell-cushion:hover {
  color: #898b96;
}
.fc-button:active, .fc-button:focus, .fc-button:focus span {
  color: #fff!important;
}
.fc-button:focus, .fc-button:active, .fc-button-active {
  background-color: #0D99FF !important;
  color: #fff!important;
  border: unset!important;
  box-shadow: unset!important;
}
.todo-timeline::before{
  height: calc(100% - 30px);
  top: 15px;
}
.todo-timeline-circle{
  border: 0.89rem solid transparent!important;
}
.todo-timeline-item{
  margin-bottom: 0!important;
}
.todo-timeline-item-text{
  padding-top: 3px!important;
  color: unset!important;
  font-size: 12px!important;
}
.todo-timeline-circle i {
  font-size: 0.70rem!important;
  left: -0.43rem!important;
  top: -0.36rem!important;
}

.checklist {
  position: relative;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  .checklist-delete-icon {
    width: 30px;
    height: 30px;
    z-index: 4;
    position: absolute;
    border: 2px solid var(--white);
    background-color: var(--danger);
    top: -12px;
    right: -19px;
    padding: 0;
    transition: none;
    visibility: hidden;
    font-size: 14px;
    color: var(--white);
  }
  &:hover {
    .checklist-delete-icon {
      visibility: visible;
    }
  }
  .checklist-title {
    color: #525f7f;
    display: flex;
    align-items: center;
    margin-left: 2.6rem;
    button {
      word-break: break-all;
      white-space: pre-wrap;
      text-align: left;
    }
  }
  .checklist-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin-left: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    border-bottom: 1px solid var(--secondary);
    img {
      visibility: hidden;
    }
    .form-check {
      display: flex;
      padding: 0;
      .custom-checkbox {
        margin-top: 3px;
      }
    }
    label {
      margin-bottom: 0;
    }
    .close {
      opacity: 0;
    }
    &:hover {
      background-color: rgba(217, 217, 217, 0.12);
      .close {
        opacity: 1;
      }
      img {
        visibility: visible;
      }
    }
    &:last-child {
      border-bottom: none !important;
    }
  }
  //Checklist Progress
  .checklist-progress {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #f0f1f3;
    height: 0.6rem;
    border-radius: 5rem;

    .percent {
      transition: all 0.3s;
      border-radius: 5rem;
      height: 100%;
    }
  }
}

@media print {
  .print_content {
    padding: 2rem 6rem;
  }
}

.attachments {
  display: flex;
  flex-wrap: wrap;
}
.attachment-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 20%;
  height: 100%;
  margin-bottom: 20px;
}
.attachment-item:hover .attachment-icon{
    opacity: 1;
}
.attachment-icon {
  opacity: 1;
}
.attachment-download-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: 30px;
}
.note-editor.note-frame.fullscreen, .note-editor.note-airframe.fullscreen{
  background-color: #FFFFFF;
}

.tox.tox-tinymce.tox-fullscreen{
  height: 100%!important;
}


@media (max-width: 768px) {

  .fc-header-toolbar {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    gap: 15px
  }

  .kt-grid--animateContent{
    height: calc(100dvh - 50px);
  }
  #kt_content{
    min-height: calc(100dvh - 600px);
  }
}

figure table{
  width: 100%!important;
}

body {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
